<template>
  <div class="card">
    <div class="card-container p-4">
      <Card>
        <template #title>
          Add a user
        </template>
        <template #content>
          <div class="grid p-fluid">
            <div class="col-12 md:col-6">
              <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
                <InputText id="name" type="text" v-model="name" placeholder="Name"></InputText>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="p-inputgroup flex flex-wrap align-items-center justify-content-center">
                <div class="field-radiobutton px-3">
                  <RadioButton id="male" name="gender" value="male" v-model="gender"/>
                  <label for="male">Male</label>
                </div>
                <div class="field-radiobutton px-3">
                  <RadioButton id="female" name="gender" value="female" v-model="gender"/>
                  <label for="female">Female</label>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-12">
              <div class="p-inputgroup flex flex-wrap align-items-center justify-content-center ">
              <span class="p-buttonset">
                <Button label="Save" @click="addUser" icon="pi pi-check"></Button>
                <Button label="Clear" @click="clearForm" icon="pi pi-trash" class="p-button-danger"></Button>
              </span>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="card-container p-4">
      <Card>
        <template #title>Users</template>
        <template #content>
          <DataTable :value="users" sortMode="multiple" removableSort>
            <Column field="name" header="Name" :sortable="true"></Column>
            <Column field="gender" header="Gender" :sortable="true"></Column>

            <Column headerStyle="width: 8em" bodyStyle="text-align: center">
              <template #body="slotProps">
                <Button type="button" icon="pi pi-trash" class="p-button-danger"
                        @click="deleteUser(slotProps.data)"></Button>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>

import InputText from "primevue/inputtext";
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton'

export default {
  name: "VForm",
  components: {InputText, Card, DataTable, Column, Button, RadioButton},
  data() {
    return {
      name: '',
      gender: '',
      users:
          [
            {name: "Harry", gender: "male"},
            {name: "Susan", gender: "female"},
            {name: "Brian", gender: "male"},
            {name: "Fred", gender: "male"},
          ],
    }
  },
  methods: {
    addUser() {
      this.users.push({name: this.name, gender: this.gender});
      this.clearForm();
    },
    deleteUser(user) {
      this.users = this.users.filter(u => u !== user)
    },
    clearForm() {
      this.name = '';
      this.gender = '';
    }
  }
}
</script>

<style scoped>

</style>